import { AnyAction } from "redux";
import { MissingInventory, MissingInventoryList } from "./missing-inventory";
import { postTypesOnlyToCheck } from "../../constants/Consts";
import { ProductCodes } from "../../shared/enums/product-code";
export interface PostType {
  type: string;
  count: number;
}

export interface InventoryItemModel {
  id: any;
  type: any;
  quantity: number;
  in_stock: number;
  width: number;
  post?: PostType[];
  description: string;
  inventory_item_has_photo: string;
  label: string;
  preference?: string;
}

export interface InstState {
  signList: InventoryItemModel[];
  riderList: InventoryItemModel[];
  rentalRiderList: InventoryItemModel[];
  lockboxList: InventoryItemModel[];
  missingInventoryList: MissingInventoryList;
}

const INITIAL_STATE: InstState = {
  signList: [],
  riderList: [],
  lockboxList: [],
  rentalRiderList: [],
  missingInventoryList: new MissingInventoryList()
};

const updateItemWithRemovedPost = (
  item: InventoryItemModel,
  postType: string
) => {
  const newItem = { ...item };
  const filteredPost = newItem.post?.filter((post) => post.type !== postType);

  if (filteredPost && newItem.post?.length !== filteredPost.length) {
    const removedCount = newItem.post!.find(
      (post) => post.type === postType
    )?.count;

    if (removedCount) {
      newItem.quantity -= removedCount;
      newItem.in_stock += removedCount;
    }

    newItem.post = filteredPost;
  }

  return newItem;
};

export const instReducer = (
  state: InstState = INITIAL_STATE,
  action: AnyAction
): InstState => {
  const { type, payload } = action;

  switch (type) {
    case "FETCH_SIGN_LIST_NEW":
      return {
        ...state,
        signList: payload.signList,
      };
    case "FETCH_RIDER_LIST_NEW":
      return {
        ...state,
        riderList: payload.riderList,

      };
    case "FETCH_RENTAL_RIDER_LIST_NEW":
      return {
        ...state,
        rentalRiderList: payload.rentalRiderList,
      };
    case "FETCH_LOCKBOX_LIST_NEW":
      return {
        ...state,
        lockboxList: payload.lockboxList,
      };
    case "FETCH_MISSING_INVENTORY_LIST_NEW":
      return {
        ...state,
        missingInventoryList: new MissingInventoryList()
      };
    case "ADD_TO_SIGN_ITEM_LIST_NEW": {
      const index = state.signList.findIndex((item) => item.id === payload.id);

      if (index !== -1) {
        const newItem = { ...state.signList[index] };
        const { postType } = payload;

        if (!newItem.post) {
          newItem.post = [];
        }

        const existingPostIndex = newItem.post.findIndex(
          (post) => post.type === postType
        );

        if (postType !== null) {
          if (existingPostIndex !== -1) {
            newItem.post[existingPostIndex].count++;
          } else {
            newItem.post.push({
              type: postType,
              count: 1,
            });
          }
        }

        if (newItem.in_stock > 0) {
          newItem.in_stock--;
          newItem.quantity++;
        }

        const updatedSignList = [...state.signList];
        updatedSignList[index] = newItem;

        return {
          ...state,
          signList: updatedSignList,
        };
      }

      return state;
    }
    case "FLUSH_MISSING_INVENTORY": {
      return {
        ...state,
        missingInventoryList: new MissingInventoryList()
      };
      return state;
    }
    case "COMMIT_MISSING_INVENTORY": {
      return {
        ...state,
        missingInventoryList: payload.missingInventoryState
      }
    }
    case "REMOVE_FROM_SIGN_ITEM_LIST_NEW": {
      const index = state.signList.findIndex((item) => item.id === payload.id);

      if (index !== -1) {
        const newItem = { ...state.signList[index] };
        const { postType } = payload;
        const existingPostIndex = newItem.post.findIndex(
          (post) => post.type === postType
        );

        if (newItem.quantity > 0) {
          newItem.in_stock++;
          newItem.quantity--;

          if (postType !== null && newItem.post[existingPostIndex].count > 0) {
            newItem.post[existingPostIndex].count--;

            if (newItem.post[existingPostIndex].count === 0) {
              newItem.post.splice(existingPostIndex, 1);
            }
          }
        }

        const updatedSignList = [...state.signList];
        updatedSignList[index] = newItem;

        return {
          ...state,
          signList: updatedSignList,
        };
      }

      return state;
    }
    case "ADD_TO_RIDER_ITEM_LIST_NEW": {
      //get item index by id
      const index = state.riderList.findIndex((item) => item.id === payload.id);

      if (index !== -1) {

        const newItem = { ...state.riderList[index] };
        const { postType } = payload;

        if (!newItem.post) {
          newItem.post = [];
        }

        const existingPostIndex = newItem.post.findIndex(
          (post) => post.type === postType
        );

        if (postType !== null) {
          if (existingPostIndex !== -1) {
            newItem.post[existingPostIndex].count++;
          } else {
            newItem.post.push({
              type: postType,
              count: 1,
            });
          }
        }

        if (newItem.in_stock > 0) {
          newItem.in_stock--;
          newItem.quantity++;
        }

        const updatedRiderList = [...state.riderList];
        updatedRiderList[index] = newItem;

        return {
          ...state,
          riderList: updatedRiderList,
        };
      }

      return state;
    }
    case "REMOVE_FROM_RIDER_ITEM_LIST_NEW": {
      const index = state.riderList.findIndex((item) => item.id === payload.id);

      if (index !== -1) {
        const newItem = { ...state.riderList[index] };
        const { postType } = payload;
        const existingPostIndex = newItem.post.findIndex(
          (post) => post.type === postType
        );

        if (newItem.quantity > 0) {
          newItem.in_stock++;
          newItem.quantity--;

          if (postType !== null && newItem.post[existingPostIndex].count > 0) {
            newItem.post[existingPostIndex].count--;

            if (newItem.post[existingPostIndex].count === 0) {
              newItem.post.splice(existingPostIndex, 1);
            }
          }
        }

        const updatedRiderList = [...state.riderList];
        updatedRiderList[index] = newItem;

        return {
          ...state,
          riderList: updatedRiderList,
        };
      }

      return state;
    }
    case "UPDATE_RIDER_PREFERENCE": {
      const index = state.riderList.findIndex((item) => item.id === payload.typeId);
      const newPreference = payload.preference;

      if (index !== -1) {
        const newItem = { ...state.riderList[index] };
        newItem.preference = newPreference;

        const updatedRiderList = [...state.riderList];
        updatedRiderList[index] = newItem;

        return {
          ...state,
          riderList: updatedRiderList,
        };
      }
      return state;
    }
    case "ADD_TO_RENTAL_RIDER_ITEM_LIST_NEW": {
      const index = state.rentalRiderList.findIndex(
        (item) => item.id === payload.id
      );

      if (index !== -1) {
        const newItem = { ...state.rentalRiderList[index] };

        if (newItem.in_stock > 0) {
          newItem.in_stock--;
          newItem.quantity++;
        }

        const updatedRentalRiderList = [...state.rentalRiderList];
        updatedRentalRiderList[index] = newItem;

        return {
          ...state,
          rentalRiderList: updatedRentalRiderList,
        };
      }

      return state;
    }
    case "REMOVE_FROM_RENTAL_RIDER_ITEM_LIST_NEW": {
      const index = state.rentalRiderList.findIndex(
        (item) => item.id === payload.id
      );

      if (index !== -1) {
        const newItem = { ...state.rentalRiderList[index] };

        if (newItem.quantity > 0) {
          newItem.in_stock++;
          newItem.quantity--;
        }

        const updatedRentalRiderList = [...state.rentalRiderList];
        updatedRentalRiderList[index] = newItem;

        return {
          ...state,
          rentalRiderList: updatedRentalRiderList,
        };
      }

      return state;
    }

    case "CLEAR_RENTAL_RIDER_SELECTIONS": {
      const newRentalRiderList = state.rentalRiderList.map((item) => {
        if (item.quantity > 0) {
          return {
            ...item,
            in_stock: item.in_stock + item.quantity,
            quantity: 0
          }
        }
        return item;
      })
      return { ...state, rentalRiderList: newRentalRiderList }
    }
    case "UPDATE_RENTAL_RIDER_PREFERENCE": {
      const index = state.rentalRiderList.findIndex((item) => item.id === payload.typeId);
      const newPreference = payload.preference;

      if (index !== -1) {
        const newItem = { ...state.rentalRiderList[index] };
        newItem.preference = newPreference;

        const updatedRiderList = [...state.rentalRiderList];
        updatedRiderList[index] = newItem;

        return {
          ...state,
          rentalRiderList: updatedRiderList,
        };
      }
      return state;
    }

    case "ADD_TO_LOCKBOX_ITEM_LIST_NEW": {
      const index = state.lockboxList.findIndex(
        (item) => item.id === payload.id
      );

      if (index !== -1) {
        const newItem = { ...state.lockboxList[index] };
        const { postType } = payload;

        if (!newItem.post) {
          newItem.post = [];
        }

        const existingPostIndex = newItem.post.findIndex(
          (post) => post.type === postType
        );

        if (postType !== null) {
          if (existingPostIndex !== -1) {
            newItem.post[existingPostIndex].count++;
          } else {
            newItem.post.push({
              type: postType,
              count: 1,
            });
          }
        }

        if (newItem.in_stock > 0) {
          newItem.in_stock--;
          newItem.quantity++;
        }

        const updatedLockboxesList = [...state.lockboxList];
        updatedLockboxesList[index] = newItem;

        return {
          ...state,
          lockboxList: updatedLockboxesList,
        };
      }

      return state;
    }
    case "REMOVE_FROM_LOCKBOX_ITEM_LIST_NEW": {
      const index = state.lockboxList.findIndex(
        (item) => item.id === payload.id
      );

      if (index !== -1) {
        const newItem = { ...state.lockboxList[index] };
        const { postType } = payload;
        const existingPostIndex = newItem.post.findIndex(
          (post) => post.type === postType
        );

        if (newItem.quantity > 0) {
          newItem.in_stock++;
          newItem.quantity--;

          if (postType !== null && newItem.post[existingPostIndex].count > 0) {
            newItem.post[existingPostIndex].count--;

            if (newItem.post[existingPostIndex].count === 0) {
              newItem.post.splice(existingPostIndex, 1);
            }
          }
        }

        const updatedLockboxesList = [...state.lockboxList];
        updatedLockboxesList[index] = newItem;

        return {
          ...state,
          lockboxList: updatedLockboxesList,
        };
      }

      return state;
    }

    case "REMOVE_POST": {
      const { postType } = payload;

      const updatedSignList = state.signList.map((item) =>
        updateItemWithRemovedPost(item, postType)
      );

      const updatedRiderList = state.riderList.map((item) =>
        updateItemWithRemovedPost(item, postType)
      );
      const updatedLockboxesList = state.lockboxList.map((item) =>
        updateItemWithRemovedPost(item, postType)
      );

      return {
        ...state,
        signList: updatedSignList,
        riderList: updatedRiderList,
        lockboxList: updatedLockboxesList,
      };
    }
    case "SINGLE_INV_ITEM_ONLY": {
      const updateListWithSingleInvItem = (list: InventoryItemModel[]) =>
        list.map((item) => {
          const itemInvOnlyPostType = () => {
            return item.post.some(p => p.type == ProductCodes.INSTALL_RIDER_ONLY || p.type == ProductCodes.INSTALL_SIGN_ONLY || p.type == ProductCodes.INSTALL_LOCKBOX_ONLY);
          }

          if (item.post && item.post.length > 0 && !itemInvOnlyPostType()) {
            return {
              ...item,
              in_stock: item.in_stock + item.quantity,
              quantity: 0,
              post: [],
            };
          }
          return item;
        });

      return {
        ...state,
        signList: updateListWithSingleInvItem(state.signList),
        riderList: updateListWithSingleInvItem(state.riderList),
        lockboxList: updateListWithSingleInvItem(state.lockboxList),
      };
    }
    case "REMOVE_INV_ITEM_ONLY": {
      const { type } = payload;

      // Determine the list to update based on the item type.
      let updatedList;
      switch (type) {
        case "sign":
          updatedList = [...state.signList];
          break;
        case "rider":
          updatedList = [...state.riderList];
          break;
        case "lockbox":
          updatedList = [...state.lockboxList];
          break;
        default:
          return state;
      }

      // Update the items in the list of the specified type.
      updatedList = updatedList.map((item) => {
        if (item.type === type) {
          // Set the quantity to 0 and update in_stock.
          const newInStock = item.in_stock + item.quantity;
          return {
            ...item,
            quantity: 0,
            in_stock: newInStock,
          };
        }
        return item; // Return unchanged items of other types.
      });

      // Update the appropriate list in the state based on the item type.
      switch (type) {
        case "sign":
          return {
            ...state,
            signList: updatedList,
          };
        case "rider":
          return {
            ...state,
            riderList: updatedList,
          };
        case "lockbox":
          return {
            ...state,
            lockboxList: updatedList,
          };
        default:
          return state; // If the type is not recognized, return the current state.
      }
    }

    case "REMOVE_INV_ITEM_FROM_CART": {
      const { invType, id } = payload;

      let updatedSignList = state.signList;
      let updatedRiderList = state.riderList;
      let updatedLockboxList = state.lockboxList;

      switch (invType) {
        case "sign":
          updatedSignList = state.signList.map((item) =>
            item.id === id
              ? {
                ...item,
                post: [],
                quantity: 0,
                in_stock: item.quantity + item.in_stock,
              }
              : item
          );
          break;
        case "rider":
          updatedRiderList = state.riderList.map((item) =>
            item.id === id
              ? {
                ...item,
                post: [],
                quantity: 0,
                in_stock: item.quantity + item.in_stock,
              }
              : item
          );
          break;
        case "lockbox":
          updatedLockboxList = state.lockboxList.map((item) =>
            item.id === id
              ? {
                ...item,
                post: [],
                quantity: 0,
                in_stock: item.quantity + item.in_stock,
              }
              : item
          );
          break;
        default:
          break;
      }

      return {
        ...state,
        signList: updatedSignList,
        riderList: updatedRiderList,
        lockboxList: updatedLockboxList,
      };
    }

    case "MAKE_LOCKBOX_STATE_DEFAULT": {

      return {
        ...state,
        lockboxList: state.lockboxList.map((item) => ({
          ...item,
          quantity: 0,
          in_stock: item.in_stock + item.quantity,
          post:null
        })),
        missingInventoryList: MissingInventoryList.removeMissingInventory(state.missingInventoryList,'lockbox')
      };
    }

    case "MAKE_SIGN_STATE_DEFAULT": {

      return {
        ...state,
        signList: state.signList.map((item) => ({
          ...item,
          quantity: 0,
          in_stock: item.in_stock + item.quantity,
          post:null
        })),
        missingInventoryList: MissingInventoryList.removeMissingInventory(state.missingInventoryList,'sign')
      };
    }

    case "MAKE_RIDER_STATE_DEFAULT": {

      return {
        ...state,
        riderList: state.riderList.map((item) => ({
          ...item,
          quantity: 0,
          in_stock: item.in_stock + item.quantity,
          post:null
        })),
        missingInventoryList: MissingInventoryList.removeMissingInventory(state.missingInventoryList,'rider')
      };
    }


    default:
      return state;
  }
};

export default instReducer;
